import { ButtonHTMLAttributes } from "react";
import joinClassNames from "classnames";

import classes from "./styles/classes.module.scss";

const BlankButton = ({
  children,
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button className={joinClassNames(classes.blank, className)} {...props}>
    {children}
  </button>
);

export default BlankButton;
